.date-range-filter .DateRangePicker {
  width: auto;
}
.date-range-filter .DateInput__small {
  width: 104px !important;
}
.date-range-filter .DateRangePickerInput_arrow {
  padding: 0;
  background-color: transparent;
  border: none;
  border-left: none;
  border-right: none;
  height: auto;
}
.date-range-filter .DateRangePickerInput_calendarIcon,
.SingleDatePickerInput_calendarIcon {
  height: auto;
  border-radius: 0;
}
.date-range-filter .DateInput_input.DateInput_input__focused {
  border: 0;
  box-shadow: none;
}
