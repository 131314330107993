//
// Form-Upload
//

/* Dropzone */
.dropzone {
  position: relative;
  min-height: 130px;
  border: 2px dashed $gray-400;
  background: $card-bg;
  border-radius: 6px;

  .dropzone-existing-previews {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #ced4da;
    padding: 2px;
    margin: 2px;
  }

  .dz-message {
    font-size: 22px;
    width: 100%;
  }
}
