.notification-preview {
  .phone-frame {
    width: 350px;
    min-height: 320px;
    max-height: 860px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0px auto;
    overflow: hidden;

    &.ios {
      background-image: url(../../assets/images/bg-iphone.svg);
    }
    &.android {
      background-image: url(../../assets/images/bg-android.svg);
    }
  }
  .notification-container {
    position: relative;
    margin-top: 60px;
    margin-bottom: 10px;
    left: 30px;
    cursor: pointer;
    width: 290px;
  }
  .notification {
    border-radius: 20px;
    box-shadow: rgb(220, 220, 220) 0px 0px 8px 0px;
    max-width: 290px;
    background-color: white;
    overflow-y: hidden;

    img.ios {
      display: block;
      width: 100%;
    }
    img.android {
      grid-area: image;
      display: flex;
      width: 100%;
      max-height: 160px;
      object-fit: cover;
      background-color: rgb(255, 255, 255);
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      border-radius: 16px;
    }
  }
  .notification-wrapper {
    display: grid;
    grid-template-columns: 36px 175px 28px;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'appIcon titlesAndBody timeAndThumbnail';
    gap: 0px 10px;
    padding: 12px 16px 12px 12px;

    img {
      grid-area: bigImage;
      display: block;
      width: 100%;
    }
  }
  .notification-icon {
    grid-area: appIcon;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      margin-top: 3px;
      background-image: url(../../assets/images/apple-icon.svg);
    }
  }
  .notification-content {
    grid-area: titlesAndBody;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;

    h5 {
      font-size: 13px;
      letter-spacing: -0.09px;
      color: rgb(0, 0, 0);
      user-select: none;
      font-weight: 500;
      line-height: 16px;
      margin: 0px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    p {
      text-overflow: ellipsis;
      max-height: 200px;
      box-sizing: content-box;
    }
  }
  .notification-time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-area: timeAndThumbnail;
    gap: 10px;

    p {
      text-align: right;
      font-size: 11px;
      line-height: 0.75;
    }
  }
  .device-select {
    width: 150px;
    margin: 20px auto;
  }
}
